import LatestNewsOne from "../../components/blogs/LatestNewsOne";
import Contact from "../../components/contact/Contact";
import AccessInDepthInformation from "../../components/features/FeatureImgThree";
import FeatureImgTwo from "../../components/features/FeatureImgTwo";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Promo from "../../components/promo/Promo";
import Screenshot from "../../components/screenshot/Screenshot";
import Team from "../../components/team/Team";
import TruestedBySellers from "../../components/testimonial/BrandCarousel";
import HeroFour from "./HeroFour";
import AboutGoal from "../../components/about/AboutGoal";
import FeatureImg from "../../components/features/FeatureImg";
import FeatureImgFour from "../../components/features/FeatureImgFour";
import Accordion from "../../components/Faqs/Accordion";
import PromoTwo from "../../components/promo/PromoTwo";


export default function HomeFour() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroFour />
      <Promo />  
      <PromoTwo />  
      <FeatureImgTwo />
      <AccessInDepthInformation />
      <FeatureImgFour/>
      <AboutGoal/>
      <FeatureImg ImgSource="assets/img/addon-feature-2.jpg" />
      {/* <Cta /> */}
      {/* <FeatureImgFour/> */}
      {/* <VideoPromoTwo /> */}
      {/* <Price hasBg /> */}
      
      <Accordion Title={true}/>
      
      <Screenshot />
      <Team bgColor="gray-light-bg" />
      {/* <LatestNewsOne light /> */}
      <Contact bgColor />
      <TruestedBySellers />
      {/* <Subsribe /> */}
      <Footer space />
      
    </Layout>
    
  );
}

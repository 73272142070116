import React, { useState } from "react";
import axios from 'axios';
export default function Subsribe() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  async function postValue(){
    try {
      const response = await axios.post('https://singlespace.free.beeceptor.com/subscribe', {
        // Request body data
        email
      });
      if (response.data.status === "Thank you for subscribe Us!") {
        setStatus(response.data.status);
      }
      console.log(response.data); // Log the response data
    } catch (error) {
      console.error(error); // Log any error
    }

  }
  return (
    <>
      <div className="shape-img subscribe-wrap">
        <img
          src="assets/img/footer-top-shape.png"
          alt="footer shape"
          className="img-fluid"
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <form
                action="#"
                method="post"
                className="subscribe-form subscribe-form-footer d-none d-md-block d-lg-block"
              >
                <div className="d-flex align-items-center">
                  <input
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    className="form-control input"
                    id="email-footer"
                    name="email"
                    placeholder="info@yourdomain.com"
                    
                    
                  />
                  
                  <input
                  onClick={postValue}
                    type="button"
                    className="button btn solid-btn"
                    id="submit-footer"
                    value="Subscribe"
                  />
                </div>
              </form>
              {status && (
        <div className="status-message">{status}</div>
      )}
            </div>
            
          </div>
        </div>
      </div>
      
    </>
  );
}

import React from "react";
import { Bounce, Fade, Flip, Hinge, JackInTheBox, Roll, Rotate, Zoom } from "react-awesome-reveal";

export default function PromoTwo() {
  return (
    <>
      <section id="features" className="promo-section ptb-100  gray-light-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
              <div className="section-heading mb-5">
                <span className="text-uppercase bg-color-6 sub-title">
                  Key features
                </span>
                <Zoom>
                <h2 className="mb-6  ">
                  We Will Helps you to Build Your Shop Online
                </h2>
                </Zoom>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-lg-3 col-sm-6 mb-lg-0">
              <Zoom>
              <div className="card single-promo-card single-promo-hover">
                
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-eye icon-md bg-color-6"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    
                    <h5>Visibility</h5>
                    
                    <p className="text-muted mb-0">
                    Expand reach effortlessly, boost online presence.
                    </p>
                    
                  </div>
                </div>
              </div></Zoom>
            </div>
            <div className="col-lg-3 col-sm-6">
              <Zoom>
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-link icon-md bg-color-6"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    
                    <h5>Seamlessness</h5>
                    
                    <p className="text-muted mb-0">
                    Simplify operations, enhance shopping experience.
                    </p>
                  </div>
                </div>
              </div></Zoom>
            </div>
            <div className="col-lg-3 col-sm-6">
              <Zoom>
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-mobile icon-md bg-color-6"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    
                    <h5>Mobility</h5>
                    
                    <p className="text-muted mb-0">
                    Access anytime, anywhere for on-the-go shopping.
                    </p>
                  </div>
                </div>
              </div></Zoom>
            </div>
            <div className="col-lg-3 col-sm-6">
              <Zoom>
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                
                <div className="card-body">
                  
                  <div className="pb-2">
                    <span className="ti-shopping-cart icon-md bg-color-6"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    
                    <h5>Upselling</h5>
                    
                    <p className="text-muted mb-0">
                    Increase sales with upselling, showcase related products.
                    </p>
                  </div>
                </div>
              </div></Zoom>
            </div>
          </div>
          
        </div>
      </section>
    </>
  );
}

import { Fade, JackInTheBox, Roll, Slide, Zoom } from "react-awesome-reveal";

export default function HeroFour() {
  return (
    <>
      <section id="home" className="hero-section  ptb-100">
        <div className="circles">
          <div className="point animated-point-1"></div>
          <div className="point animated-point-2"></div>
          <div className="point animated-point-3"></div>
          <div className="point animated-point-4"></div>
          <div className="point animated-point-5"></div>
          <div className="point animated-point-6"></div>
          <div className="point animated-point-7"></div>
          <div className="point animated-point-8"></div>
          <div className="point animated-point-9"></div>
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
            
              <div className="hero-content-left ptb-100 text-black">
              <JackInTheBox>
                <h1 className="text-black">
                  
                  <span>Welcome to SingleSpace</span>
                  
                </h1>
                </JackInTheBox>
                <Zoom cascade damping={0.4} >
                <p className="lead">
                Empowering Shop Owners in the Digital Marketplace. Showcase your products, reach a wider audience, and boost sales. Streamline your online presence and connect with customers seamlessly. Join now and unlock your business's full potential.
                </p>
                <div>
                <a href="https://seller.singlespace.in/login" target="_blank" className="btn  app-store-btn">
                  Create Your Online Store
                </a>
                <a href="https://ss.singlespace.shop/" target="_blank" className="btn  app-store-btn">
                  Go To Shop Demo
                </a>
                </div>
                </Zoom>
              </div>
              
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="hero-animation-img">
                <img
                  className=" d-block animation-one"
                  src="assets/img/banner-1.png"
                  alt="animation "
                />
                {/* <img
                  className="img-fluid d-none d-lg-block animation-two"
                  src="assets/img/hero-animation-01.svg"
                  alt="animation "
                  width="120"
                /> */}
                {/* <img
                  className="img-fluid d-none d-lg-block animation-three"
                  src="assets/img/hero-animation-02.svg"
                  alt="animation "
                  width="120"
                /> */}
                {/* <img
                  className="img-fluid d-none d-lg-block animation-four"
                  src="assets/img/hero-animation-03.svg"
                  alt="animation "
                  width="230"
                /> */}
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/hero-bg-shape-2(1).svg"
          className="shape-image"
          alt="shape "
        />
      </section>
    </>
  );
}

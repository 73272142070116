import React, { useState } from "react";
import { Roll, Slide, Zoom } from "react-awesome-reveal";
import axios from 'axios';
import Validation from '../../helpers/validation'
import { API_URL } from "../../config";
export default function Contact({ bgColor }) {
  const [contactForm, setContactForm] = useState({
    name:null,
    nameError:null,

    email:null,
    emailError:null,
    
    phone:null,
    phoneError:null,

    message:null,
    messageError:null,
  });

  const [error, setError] = useState('success');
  const [errorMsg, setErrorMsg] = useState(null);


 const  handleInput = (event=>{
    let name = event.target.name;
    let err = event.target.getAttribute('err');
    setContactForm(prev => ({...prev,[name]: event.target.value,[err]:null}));
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    let error = false;
      if(!Validation.validate(contactForm.name,'notEmpty')){
        error = true;
        setContactForm(prev=>({...prev,nameError:'Name cannot be blank.'}));
      }

      if(!Validation.validate(contactForm.phone,'notEmpty')){
        error = true;
        setContactForm(prev=>({...prev,phoneError:'Phone cannot be blank.'}));
      }else if(!Validation.validate(contactForm.phone,'phone')){
        error = true;
        setContactForm(prev=>({...prev,phoneError:'Enter a valid phone.'}));
      }

      if(!Validation.validate(contactForm.email,'notEmpty')){
        error = true;
        setContactForm(prev=>({...prev,emailError:'Email cannot be blank.'}));
      }else if(!Validation.validate(contactForm.email,'email')){
        error = true;
        setContactForm(prev=>({...prev,emailError:'Enter a valid email.'}));
      }

      if(!Validation.validate(contactForm.message,'notEmpty')){
        error = true;
        setContactForm(prev=>({...prev,messageError:'Message cannot be blank.'}));
      }
      if(!error){
        saveContact();
      }

  };


  // const getName=(e)=>{
  //   console.log(e.target.value);
  //   setName(e.target.value)
  // }
  async function saveContact(){
    setError('success');
    let param = {name:contactForm.name,phone:contactForm.phone,email:contactForm.email,message:contactForm.message};
    try {
      const response = await axios.post(`${API_URL}/contact-enquiries`, param,{headers:{"Content-Type":"application/x-www-form-urlencoded"}});
      let {data} = response.data;
      console.log('response',data)
      if(response.status==200){
        setError('success');
        setErrorMsg(data.message);
      }else {
        setErrorMsg(data.message);
        setError('danger');
      }
    } catch (error) {
      setError('danger');
      setErrorMsg('Something went wrong. Please try again later.');
      console.error(error); // Log any error
    }

  }

  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <Zoom>
                <h2>Contact With Us</h2>
                
                <p>
                Have questions, feedback, or need assistance? Our team is ready to assist you. Reach out to us for prompt and personalized support. We're dedicated to ensuring your experience with SingleSpace is seamless and satisfying. Your inquiries are important to us, so don't hesitate to get in touch.
                </p></Zoom>
              </div>
              <Zoom>
              <div className="footer-address">
              
               
                {/* <ul>
                  <li>
                    <span>Phone:</span>
                  </li>
                  <li>
                    <span>
                      Email :
                      <a href="mailto:hello@yourdomain.com">
                        
                      </a>
                    </span>
                  </li>
                </ul> */}
              </div>
              </Zoom>
            </div>
            <div className="col-md-7">
              {
                errorMsg &&
                <div class={`alert alert-${error}`} role="alert">
                 {errorMsg}
                </div>
              }
           
              <form
                onSubmit={handleSubmit}
                action=""
                method="POST"
                id="contactForm"
                className="contact-us-form"
              ><Zoom>
                <h5>Reach us quickly</h5>
                </Zoom>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        // onChange={getName}
                        onChange={(e) => handleInput(e)}
                        type="text"
                        className="form-control"
                        name="name"
                        err="nameError"
                        id="name"
                        placeholder="Enter name"
                        // required="required"
                      />
                    
                        <div className="red" role="alert">
                         {contactForm.nameError}
                        </div>
                      
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        onChange={(e) => handleInput(e)}
                        type="text"
                        name="phone"
                        defaultValue=""
                        err="phoneError"

                        className="form-control"
                        id="phone"
                        placeholder="Your Phone"
                      />
                    
                        <div className="red" role="alert">
                          {contactForm.phoneError}
                        </div>
                      
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        onChange={(e) => handleInput(e)}
                         type="text"
                        className="form-control"
                        name="email"
                        id="email"
                        err="emailError"

                        placeholder="Enter email"
                        // required="required"
                      />
                      
                        <div className="red" role="alert">
                          {contactForm.emailError}
                        </div>
                    
                    </div>
                  </div>
                </div>
               
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        onChange={(e) => handleInput(e)}
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        err="messageError"

                        cols="25"
                        placeholder="Message"
                      ></textarea>
                      
                        <div className="red" role="alert">
                          {contactForm.messageError}
                        </div>
                      
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn  solid-btn"
                      id="btnContactUs"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

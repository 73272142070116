import React from "react";
import { Bounce, JackInTheBox, Zoom } from "react-awesome-reveal";

export default function Accordion({ Title }) {
  return (
    <>
      <section className={`container accordions ${Title ? "ptb-100" : ""}`}>
        {Title ? (
          
            <div className="accord row col-lg-8 col-md-9">
              <div className="section-heading mb-5">
                <Zoom>
                <h2>Frequently Asked Questions</h2>
                </Zoom>
                <Zoom >
                <p className="lead">
                Quickly find answers to common questions about Singlespace. Learn how it works, create your shop, choose payment methods, handle fees, manage returns, and understand the payment process.
                </p>
                </Zoom>
              </div>
            </div>
          
        ) : (
          ""
        )}
       
        <div className="row accord">
          <div className=" col-lg-6">
            <div id="accordion-1" className="accordion accordion-faq">
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-1"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-1"
                  aria-expanded="false"
                  aria-controls="collapse-1-1"
                ><Zoom>
                  <h6 className="mb-0">
                    <span className="ti-info mr-3 color-secondary"></span>
                    What is Singlespace and how does it work?
                  </h6>
                  </Zoom>
                </div>
                <div
                  id="collapse-1-1"
                  className="collapse"
                  aria-labelledby="heading-1-1"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                    Singlespace is an eCommerce platform that provides a space for shop owners to showcase and sell their products. It works by allowing shop owners to create their online shops, add products, manage inventory, and process customer orders.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-2"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-2"
                  aria-expanded="false"
                  aria-controls="collapse-1-2"
                ><Zoom>
                  <h6 className="mb-0">
                    <span className="ti-pencil-alt mr-3 color-secondary"></span>
                    How can I create my shop on Singlespace?
                  </h6>
                  </Zoom>
                </div>
                <div
                  id="collapse-1-2"
                  className="collapse"
                  aria-labelledby="heading-1-2"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                    To create your shop on Singlespace, simply sign up as a shop owner, provide the necessary information about your business, and follow the step-by-step process to set up your shop. You can then start adding products and customizing your shop to reflect your brand.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-3"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-3"
                  aria-expanded="false"
                  aria-controls="collapse-1-3"
                ><Zoom>
                  <h6 className="mb-0">
                    <span className="ti-wallet mr-3 color-secondary"></span>What payment methods are supported on Singlespace?
                  </h6>
                  </Zoom>
                </div>
                <div
                  id="collapse-1-3"
                  className="collapse"
                  aria-labelledby="heading-1-3"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                    Singlespace integrates with the CashFree payment gateway, allowing customers to make secure online payments using various payment methods such as credit cards, debit cards, net banking, and digital wallets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div id="accordion-2" className="accordion accordion-faq">
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-1"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-1"
                  aria-expanded="false"
                  aria-controls="collapse-2-1"
                ><Zoom>
                  <h6 className="mb-0">
                    <span className="ti-money mr-3 color-secondary"></span>
                    What are the fees for selling on Singlespace?
                  </h6>
                  </Zoom>
                </div>
                <div
                  id="collapse-2-1"
                  className="collapse"
                  aria-labelledby="heading-2-1"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                    Singlespace operates on a commission-based pricing model, where sellers pay a commission on each sale made through the platform. The commission rates vary based on the price range of the products sold.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-2"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-2"
                  aria-expanded="false"
                  aria-controls="collapse-2-2"
                ><Zoom>
                  <h6 className="mb-0">
                    <span className="ti-reload mr-3 color-secondary"></span>How can I handle returns and refunds on Singlespace?
                  </h6>
                  </Zoom>
                </div>
                <div
                  id="collapse-2-2"
                  className="collapse"
                  aria-labelledby="heading-2-2"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                    As a seller on Singlespace, you have the flexibility to define your own return and refund policies. You can communicate your policies clearly to customers and handle return requests and refunds through the seller dashboard.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-3"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-3"
                  aria-expanded="false"
                  aria-controls="collapse-2-3"
                ><Zoom>
                  <h6 className="mb-0">
                    <span className="ti-credit-card mr-3 color-secondary"></span> How does the payment process work on Singlespace?
                  </h6>
                  </Zoom>
                </div>
                <div
                  id="collapse-2-3"
                  className="collapse"
                  aria-labelledby="heading-2-3"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                    When a customer makes a purchase on Singlespace, the payment is securely processed through our trusted payment gateway, CashFree. The customer can choose from various payment options such as credit card, debit card, net banking, or digital wallets to complete the transaction. Once the payment is successfully processed, the amount, minus any applicable fees or commissions, will be credited to your configured bank account within a 3 business day cycle. You can easily manage your bank account details and track your earnings through your Singlespace dashboard.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </section>
    </>
  );
}

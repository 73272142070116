import React from "react";
import { Slide, Zoom } from "react-awesome-reveal";

export default function Team({ hasTitle, bgColor }) {
  return (
    <>
      <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                {hasTitle ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Lovely Team
                  </span>
                ) : (
                  ""
                )}
                <Zoom>
                <h2>Our Team Members</h2>
                </Zoom>
                <Zoom>
                <p className="lead">
                The Backbone of SingleSpace. Meet the Dedicated and Innovative Professionals Who Make It All Happen.
                </p>
                </Zoom>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-lg-2 col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <Zoom>
                  <img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairDreads01&accessoriesType=Sunglasses&hairColor=SilverGray&facialHairType=MoustacheFancy&facialHairColor=Platinum&clotheType=ShirtCrewNeck&clotheColor=Pink&eyeType=Dizzy&eyebrowType=SadConcernedNatural&mouthType=Twinkle&skinColor=Brown"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                  </Zoom>
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Abilash TS</h5>
                  <h6>Chief Technology Officer</h6>
                  {/* <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-github"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-dribbble"></span>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <Zoom>
                  <img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=NoHair&accessoriesType=Prescription02&facialHairType=BeardMajestic&facialHairColor=BrownDark&clotheType=BlazerSweater&eyeType=Happy&eyebrowType=SadConcernedNatural&mouthType=Serious&skinColor=Yellow"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                  </Zoom>
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Ajmaludheen KT</h5>
                  <h6>Lead UX Designer</h6>
                  {/* <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-github"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-dribbble"></span>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <Zoom>
                  <img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortCurly&accessoriesType=Kurt&hairColor=BlondeGolden&facialHairType=MoustacheFancy&facialHairColor=Blonde&clotheType=ShirtVNeck&clotheColor=Gray02&eyeType=Happy&eyebrowType=UpDownNatural&mouthType=Twinkle&skinColor=Brown"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                  </Zoom>
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Binoy KB</h5>
                  <h6>Customer Support Manager</h6>
                  {/* <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-github"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-dribbble"></span>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>            
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import { Bounce, Flip, Roll, Zoom } from "react-awesome-reveal";

const Promo = () => {
  return (
    <>
      <section id="about" className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-6">
              <div className="section-heading text-center mb-5">
                <Zoom cascade damping={0.4}>
                <h2>Why SingleSpace?</h2>
                
                
                <p className="lead">
                Discover the power of SingleSpace: the ultimate eCommerce platform for shop owners and customers. With features like dedicated storefronts and centralized product management, we streamline your online presence and elevate your selling experience. Join us today and unlock the future of eCommerce.
                </p>
                </Zoom>
              </div>
            </div>
          </div>
          
          <div className="row equal">
            
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-settings text-white"></span>
                </div>
                <Bounce>
                <h5>Easy Setup</h5>
                <p>SingleSpace offers a hassle-free setup process, allowing you to quickly get your online shop up and running.</p>
                </Bounce>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-package text-white"></span>
                </div>
                <Bounce>
                <h5>Centralized Management</h5>
                
                <p>
                Simplify your product management with SingleSpace's centralized repository, eliminating the need for manual data entry.
                </p>
                </Bounce>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-mobile text-white"></span>
                </div>
                <Bounce>
                <h5>Seamless Storefronts</h5>
                
                <p>
                Elevate your online presence with SingleSpace's seamless storefronts, providing a smooth and engaging shopping experience for your customers.
                </p>
              </Bounce>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;

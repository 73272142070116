import React from "react";
import { HashLink } from 'react-router-hash-link';

export default function Footer({ space }) {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 ${space ? "pt-150" : "pt-60"}`}
          style={{
            background:
              "linear-gradient(90deg,#672b85,#dc1769)",
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap footer-icon resp-footer text-white">
                  <img
                    src="assets/img/logo.png"
                    alt="footer logo"
                    width="80"
                    className="img-fluid mb-3"
                  />
                  <p>
                  Your Ultimate eCommerce Solution. Connect, Shop, and Sell with Ease.
                  </p>

                  <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                    <li className="list-inline-item">
                        <a href="https://www.linkedin.com/company/mysinglespace" target="_blank" title="Linkedin">
                          <span className="ti-linkedin"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://www.facebook.com/affordmate/" target="_blank" title="Facebook">
                          <span className="ti-facebook"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://twitter.com/affordmate" target="_blank" title="Twitter">
                          <span className="ti-twitter"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://www.instagram.com/affordmate/" target="_blank" title="Instagram">
                          <span className="ti-instagram"></span>
                        </a>
                      </li>                     
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 ">
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-6 mb-sm-6 mb-md-0 mb-lg-2">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Navigation</h5>
                      <ul className="list-unstyled">
                      <li className="mb-2">
                        <HashLink
                          to="#home"
                          className="mb-2"
                          smooth                
                        >
                          <span>Home</span>
                        </HashLink>
                        </li>
                        <li className="mb-2">
                        <HashLink
                          to="#about"
                          className="mb-2"
                          smooth                
                        >
                          <span>About</span>
                        </HashLink>
                        </li>
                        <li className="mb-2">
                        <HashLink
                          to="#features"
                          className="mb-2"
                          smooth                
                        >
                          <span>Features</span>
                        </HashLink>
                        </li>
                        <li className="mb-2">
                        <HashLink
                          to="#pricing"
                          className="mb-2"
                          smooth                
                        >
                          <span>Pricing</span>
                        </HashLink>
                        </li>
                        <li className="mb-2">
                        <HashLink
                          to="#screenshots"
                          className="mb-2"
                          smooth                
                        >
                          <span>Screenshots</span>
                        </HashLink>
                        </li>
                        <li className="mb-2">
                        <HashLink
                          to="#team"
                          className="mb-2"
                          smooth                
                        >
                          <span>Team</span>
                        </HashLink>
                        </li>
                        <li className="mb-2">
                        <HashLink
                          to="#contact"
                          className="mb-2"
                          smooth                
                        >
                          <span>Contact US</span>
                        </HashLink>
                        </li>                      
                      </ul>
                    </div>
                  </div>
                 
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Location</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-location-pin mr-2"></span>
                          4/404, Valiyakunnu, Valanchery,<br/> Kodumudi, 676552
                          <br />
                          Kerala India
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-mobile mr-2"></span>
                          <a href="tel: 087145 60828">  087145 60828</a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-email mr-2"></span>
                          <a href="mailto:sellers@singlespace.in">
                            {" "}
                            sellers@singlespace.in
                          </a>
                        </li>
                        {/* <li className="mb-2 d-flex align-items-center">
                          <span className="ti-world mr-2"></span>
                          <a href="#/"> www.yourdomain.com</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      © SingleSpace | Simplifying Online Shopping
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a className="small-text" href="https://seller.singlespace.in/terms-and-conditions" target="_blank">
                          Terms & Conditions
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="small-text" href="https://seller.singlespace.in/privacy-policy" target="_blank">
                          Privacy & Policy
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="small-text" href="https://seller.singlespace.in/pricing" target="_blank">
                          Pricing
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="small-text" href="https://seller.singlespace.in/site/about-us" target="_blank">
                          About Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

import React from "react";
import { Bounce, JackInTheBox, Zoom } from "react-awesome-reveal";

export default function FeatureImgTwo() {
  return (
    <>
      <section id="features" className="about-us ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <Zoom>
                <h2>
                Skip the Queue, Scan and Shop
                </h2>
                </Zoom>
                <Zoom>
                <div className="single-feature mb-4 mt-5">
                  
                  <div className="icon-box-wrap d-flex align-items-center mb-2">
                   
                    <div className="mr-3 bg-color-6  icon-box">
                      
                      <img
                        src="assets/img/icon/scanning.svg"
                        alt="icon "
                        className="img-fluid"
                      />
                      
                    </div>
                    
                    <p className="mb-0">
                    With SingleSpace's QR code feature, customers can enjoy a hassle-free shopping experience by accessing stores instantly without the need to wait in long queues or navigate through crowded spaces.
                    </p>
                    
                  </div>

                </div>
                </Zoom>
                <Zoom>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      
                      <img
                        src="assets/img/icon/dashboard.svg"
                        alt="icon "
                        className="img-fluid"
                      />
                      
                    </div>
                    <p className="mb-0">
                    The QR code access eliminates the need to manually search for stores or products. Customers can simply scan the code and instantly access the store, saving valuable time and enabling efficient browsing and purchasing.
                    </p>
                  </div>
                  <p></p>
                </div></Zoom>
                <Zoom>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      
                      <img
                        src="assets/img/icon/earth.svg"
                        alt="icon "
                        className="img-fluid"
                      />
                      
                    </div>
                    <p className="mb-0">
                    The QR code feature enables customers to shop anytime and anywhere. Whether they are at home, in transit, or in a physical store, they can quickly scan the code and continue their shopping journey seamlessly, making it convenient and flexible for busy individuals.
                    </p>
                  </div>
                  <p></p>
                </div></Zoom>               
              </div>
            </div>
            
            <div className="col-md-5">
              <div className="about-content-right">
                
                <img
                  src="assets/img/feature/feature-qr.png"
                  alt="about us"
                  className="img-fluid"
                />
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

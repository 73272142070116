import React from "react";
import { Bounce, Fade, Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <>
      <section
        className="download-section pt-100 "
        style={{
          background:
            "url('assets/img/app-hero-bg.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container gray-light-bg">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content text-black ">
                <Zoom>
                <h2 className="text-black">
                Comprehensive Product Catalog
                </h2>
                </Zoom>
                <Bounce>
                <p className="lead">
                With Singlespace, you get access to a complete product catalog that provides detailed and organized information for every product, ensuring a seamless shopping experience for your customers.
                </p>
                </Bounce>

                {/* <div className="download-btn">
                  <Link to="/" className="btn google-play-btn mr-3">
                    <span className="ti-android"></span> Google Play
                  </Link>
                  <Link to="/" className="btn app-store-btn">
                    <span className="ti-apple"></span> App Store
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="download-img d-flex align-items-end">
                <Fade>
                <img
                  src="assets/img/app-hand-top.png"
                  alt="download"
                  className="img-fluid"
                />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;

import React from "react";
import { Bounce, Zoom } from "react-awesome-reveal";

export default function FeatureImgThree() {
  return (
    <>
      <section id="features" className="feature-section ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-4">
              <div className="download-img">
                
                <img
                  src="assets/img/mockup-2.png"
                  alt="download"
                  className="img-fluid"
                />
                
              </div>
            </div>
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <Zoom>
                <h2>Access In-Depth Information</h2>
                </Zoom>
                <Zoom>
                <p>
                Empower your shop with complete control over product feature fields on SingleSpace. Customize and manage the fields according to your specific needs, allowing you to highlight the unique aspects of your products. Whether it's color options, size variations, or any other specific attribute, you can generate and organize the fields to accurately represent your inventory. Additionally, our platform enables you to make these feature fields searchable, making it easier for customers to find the exact products they're looking for. With SingleSpace, you have the flexibility and functionality to tailor your product feature fields to optimize the shopping experience and showcase your products effectively.
                </p>
                </Zoom>

                <div className="feature-content-wrap pt-2">
                 
                  <div className="tab-content feature-tab-content">
                 
                    <div className="tab-pane active" id="tab6-3">
                      <div className="row">
                        <div className="col single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-settings rounded mr-3 icon icon-color-2"></span>
                            <Zoom>
                            <h5 className="mb-0">Flexible Customization</h5>
                            </Zoom>
                          </div>
                          <Zoom>
                          <p>
                          Customize and manage product features to match your unique needs.
                          </p>
                          </Zoom>
                        </div>
                        <div className="col single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-search rounded mr-3 icon icon-color-3"></span>
                            <Zoom>
                            <h5 className="mb-0">Enhanced Searchability</h5>
                            </Zoom>
                          </div>
                          <Zoom>
                          <p>
                          Easily find products with specific attributes or customizable options.
                          </p>
                          </Zoom>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import { Bounce, Fade, Slide, Zoom } from "react-awesome-reveal";

export default function AboutGoal() {
  return (
    <>
      <section id="pricing" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              
              <div className="about-content-left section-heading">
                {/* <span className="text-uppercase color-secondary sub-title">
                Zero-Risk Pricing
                </span> */}
                <Zoom>
                <h2>Only Pay When You Make Sales</h2>
                
                <p>
                No fixed fees, only a commission based on your sales. It's a fair and flexible model that aligns with your business success, allowing you to focus on growth and profitability.
                </p>
                </Zoom>

                <div className="single-feature mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <span className="ti-vector rounded mr-3 icon icon-color-1"></span>
                    <Zoom>
                    <h5 className="mb-0">Cost Savings</h5>
                    </Zoom>
                  </div>
                  <Zoom>
                  <p>
                  Pay only when you make sales. No fixed fees or upfront costs, allowing you to save on unnecessary expenses and invest more in growing your business.
                  </p>
                  </Zoom>
                </div>
                <div className="single-feature mb-4">
                  <Zoom>
                  <div className="d-flex align-items-center mb-2">
                    <span className="ti-dashboard rounded mr-3 icon icon-color-2"></span>
                    
                    <h5 className="mb-0">Performance-Driven Model</h5>
                    
                  </div>
                  
                  <p>
                  Our pricing model incentivizes shops to excel. The more you sell, the more you earn. It encourages you to optimize your strategies, improve customer satisfaction, and drive your business forward.
                  </p>
                  </Zoom>
                </div>

                <div className="single-feature mb-4">
                  <Zoom>
                  <div className="d-flex align-items-center mb-2">
                    <span className="ti-alarm-clock rounded mr-3 icon icon-color-3"></span>
                    
                    <h5 className="mb-0">Fairness and Flexibility</h5>
                    
                  </div>
                  
                  <p>
                  We believe in equal opportunities. Our commission-based pricing ensures fairness for all shops, regardless of their size or experience. It's a flexible approach that allows you to scale your operations without constraints.
                  </p>
                  </Zoom>
                </div>  
                              
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="about-content-right">
                <Bounce>
                <img
                  src="assets/img/about-img.png"
                  alt="animation"
                  className="img-fluid"
                  // width="120"
                  
                  

                />
                </Bounce>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Navbar = ({ darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  
  const timeHeaderStyle = {
    color: scroll > headerTop ? "black" : "white",
  };



  return (
    <>
      <header className={`header ${classOption}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top ${darkBg ? "bg-transparent" : "custom-nav white-bg"
            } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              {darkBg ? (
                <img
                  src="assets/img/logo.png"
                  width="60"
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="assets/img/logo.png"
                  width="70"
                  alt="logo"
                  className="img-fluid"
                />
              )}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>
            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
              
                <li className="nav-item">
                  <HashLink
                    to="#home"
                    className="nav-link"
                    smooth                
                  >
                     <span style={timeHeaderStyle}>Home</span>
                  </HashLink>
                  </li>                
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to='#about'>
                     <span style={timeHeaderStyle}>About</span>
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#features">
                    <span style={timeHeaderStyle}>Features</span>
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#pricing">
                    <span style={timeHeaderStyle}>Pricing</span>
                  </HashLink>
                </li>
                {/* <li className="nav-item">
                  <HashLink className="nav-link"  smooth to="blog-sidebar">
                    <span style={timeHeaderStyle}>Blog</span>
                  </HashLink>
                </li> */}
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to='#screenshots'>
                     <span style={timeHeaderStyle}>Screenshots</span>
                  </HashLink>
                </li>                
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#team">
                  <span style={timeHeaderStyle}>Team</span>
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#contact">
                  <span style={timeHeaderStyle}>Contact US</span>
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;

import React from "react";
import { Bounce, Fade, Slide, Zoom } from "react-awesome-reveal";

export default function FeatureImg({ ImgSource }) {
  return (
    <>
      <div id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">              
              <span class="text-uppercase bg-color-6 sub-title">Comming Soon</span>
              <Zoom>
                <h2>Powerful Add-Ons to Enhance Your SingleSpace Experience</h2>
                
                <p>
                Unlock new possibilities with our add-ons that extend the functionality of your SingleSpace shop, allowing you to offer unique features and services to your customers.
                </p>
                </Zoom>
              </div>
            </div>
          </div>
          <div className="row row-grid align-items-center">
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-1 rounded-circle">
                    <span className="ti-link"></span>
                  </div>
                </div>
                <Zoom>
                <div className="icon-text">
                  
                  <h5>Domain Pointing</h5>
                  
                  <p className="mb-0">
                  Connect your own domain to your SingleSpace shop effortlessly, eliminating the need for a separate e-commerce website.
                  </p>
                </div>
                </Zoom>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-2 rounded-circle">
                    <span className="ti-palette"></span>
                  </div>
                </div>
                <Zoom>
                <div className="icon-text">
                  
                  <h5>Themes</h5>
                  
                  <p className="mb-0">
                  Choose from a variety of stunning themes to instantly transform the look and feel of your SingleSpace shop, creating a visually appealing and unique online presence.
                  </p>
                </div></Zoom>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-3 rounded-circle">
                    <span className="ti-location-pin"></span>
                  </div>
                </div><Zoom>
                <div className="icon-text">
                  
                  <h5>Multiple Outlets</h5>
                  
                  <p className="mb-0">
                  Manage multiple physical store locations within a single platform, streamlining operations and providing a seamless shopping experience across all outlets.
                  </p>
                </div>
                </Zoom>
              </div>
              
            </div>
            
            <div className="col-lg-4">
              <div className="position-relative" style={{ zindex: 10 }}>
                <Fade>
                <img
                  alt="placeholder"
                  src={ImgSource}
                  className="img-center img-fluid"
                />
                </Fade>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-tag"></span>
                  </div>
                </div><Zoom>
                <div className="icon-text">
                  
                  <h5>Coupons and Discounts</h5>
                  
                  <p className="mb-0">
                  Attract more customers and boost sales by offering special promotions, coupons, and discounts that can be easily applied during checkout on your SingleSpace shop.
                  </p>
                </div></Zoom>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-5 rounded-circle">
                    <span className="ti-user"></span>
                  </div>
                </div>
                <Zoom>
                <div className="icon-text">
                  
                  <h5>User Management</h5>
                  
                  <p className="mb-0">
                  Delegate tasks and collaborate effectively by adding multiple users to your SingleSpace shop, each with assigned roles and permissions for efficient team management.
                  </p>
                </div>
                </Zoom>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-6 rounded-circle">
                    <span className="ti-package"></span>
                  </div>
                </div>
                <Zoom>
                <div className="icon-text">
                  
                  <h5>Inventory Management</h5>
                  
                  <p className="mb-0">
                  Keep track of your inventory levels, streamline stock management, and avoid stockouts.
                  </p>
                </div></Zoom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

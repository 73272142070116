const validationLibrary = {
  isEmail: (value) => {
    // Basic email validation using a regular expression
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  },

  isPhoneNumber: (value) => {
    // Basic phone number validation using a regular expression
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(value);
  },

  isName: (value) => {
    // Name validation: Check if it's a non-empty string
    return typeof value === 'string' && value.trim() !== '';
  },

  isNotEmpty: (value) => {
    // Not empty validation: Check if it's not undefined, null, or an empty string
    return value !== undefined && value !== null && value.trim() !== '';
  },

  validate: (value, validationType) => {
    switch (validationType) {
      case 'email':
        return validationLibrary.isEmail(value);

      case 'phone':
        return validationLibrary.isPhoneNumber(value);

      case 'name':
        return validationLibrary.isName(value);

      case 'notEmpty':
        return validationLibrary.isNotEmpty(value);

      default:
        return false; // Invalid or unsupported validation type
    }
  },
};


export default validationLibrary;